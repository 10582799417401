import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-vbw.svg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/vbw-gb-website/vbw-gb.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const VBWGbWebsite = function (props, ref) {

    const data = useStaticQuery(graphql`
        query VbwGbWebsiteQuery {
            heropic: file(relativePath: { eq: "images/projekte/vbw-gb-website/landing/vbw-gb-og.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/vbw-gb-website/landing/vbw-gb-og.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/vbw-gb-website/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: BLURRED, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Digitaler Geschäftsbericht", "https://www.vbw-bochum.de/fileadmin/user_upload/Geschaeftsberichte/vbw-gb-2020/"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["html", "css", "js"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="VBW Bochum Geschäftsbericht 2020"
                description="Q:marketing realisiert ersten digitalen Geschäftsbericht für Bochumer Wohnungsunternehmen."
                image={data?.ogimage?.childImageSharp?.fixed}
            />
{/* {data.heropic.childImageSharp.gatsbyImageData}*/}
            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>VBW Bochum<br/>Geschäftsbericht 2020</h1>
                        <p className="introtext whitetextshadow">Mit Mut neue Wege gehen. Der erste rein digitale Jahresrückblick der VBW.</p>
                        <AnchorLink to="#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>                                                               
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Ansprechend aufbereitet, multimedial und zeitgemäß.",
                        "Nutzung von Inhalten wie Videos, Galerien und Before-After-Slider.",
                        "Ökologisch nachhaltig durch Verzicht auf Papier."

                    ]}
                />
            </ListenModule>
            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="christian-frieling"/>

        </Layout>
    )
}

export default VBWGbWebsite